import { ChainId, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

import Logo from '../../assets/svg/logo.svg'
import LogoDark from '../../assets/svg/logo_white.svg'
import Wordmark from '../../assets/svg/wordmark.svg'
import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings2'
import Menu from '../Menu'

import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import VersionSwitch from './VersionSwitch'
import { ExternalLink } from '../../theme'
import DexSvg from '../../assets/images/dex.svg'
import LogoPrefix from '../../assets/svg/logo_prefix.svg'
import ImgUp from '../../assets/png/up.png'
import ImgDown from '../../assets/png/down.png'
import ImgAngel from '../../assets/png/angel.png'
import ImgShare from '../../assets/png/share.png'
import useCopyClipboard from '../../hooks/useCopyClipboard'
import { useTranslation } from 'react-i18next'
import { useCurrency } from '../../hooks/Tokens'
import { useDefaultsFromURLSearch } from '../../state/swap/hooks'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
	margin-left: 0.5rem; // zyl margin-top: 0.25rem;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // display: none;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  transition: transform 1s ease;
  :hover {
    transform: rotate(360deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.375rem; // zyl
	margin-left: 0rem; // zyl
    // img { 
    //   width: 4.5rem;
    // }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    // flex-direction: column;
    // align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  display: none; // zyl
  // ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  //   display: none;
  // `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()
  
  const lastTime = localStorage.getItem('aglTime')
  const now = new Date().getTime()
  if ((lastTime?parseFloat(lastTime):0) + 5000 < now) {
	  try {
		  localStorage.setItem('aglTime', now + "")
		  // var xhr = new XMLHttpRequest()
		  // xhr.open("GET", "https://api.acptsc.com/acpt/swap/aglPrice", true)
		  // xhr.send("");
		  // xhr.onreadystatechange =function(){
		  // 			if(xhr.readyState==4 && xhr.status==200){
		  // 				localStorage.setItem('aglTime', now + "")
				// 		localStorage.setItem('aglPrice', JSON.parse(xhr.responseText).data['AGL'].first)
		  // 				localStorage.setItem('aglUpDown', JSON.parse(xhr.responseText).data['AGL'].second)
		  // 				console.log('refPrice success: ' + xhr.responseText)
		  // 			} else{
		  // 				console.log('refPrice fail: ')
		  // 			}
		  // }
	  } catch (e) {
		  console.log('ref error: ' + e)
	  }
  }
  const lastPrice = localStorage.getItem('aglPrice')
  const lastUpDown = localStorage.getItem('aglUpDown')
  
  const TransactionStatusText = styled.span`
    margin-left: 0.25rem;
    font-size: 0.825rem;
    ${({ theme }) => theme.flexRowNoWrap};
    align-items: center;
  `
  const [isCopied, setCopied] = useCopyClipboard()
  const { t } = useTranslation()
  
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  
  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: '', padding: ((urlLoadedTokens && urlLoadedTokens.length > 0 && urlLoadedTokens[0].address === "0x781e25aCC507d1c987d3c34885abE6E29e86b8a6") ? "1.75rem 1rem 0 1rem" : "0rem 1rem 0 1rem") }}>
        <HeaderElement>
          <Title href="https://mpc20.com/">
            <UniIcon>
              <img style={{ maxHeight: '2.5rem', maxWidth: '2.5rem' }} src={isDark ? LogoDark : Logo} alt="logo" />
            </UniIcon>
            <TitleText>
              <img style={{ paddingBottom: "0.25rem", maxHeight: '1.75rem', marginLeft: '1px', marginTop: '4px' }} src={isDark ? WordmarkDark : Wordmark} alt="logo" />
            </TitleText>
			<UniIcon>
			  <img style={{ maxHeight: '1rem', maxWidth: '1rem', marginLeft: '-0.8rem' }} src={LogoPrefix} alt="logo" />
			</UniIcon>
          </Title>
        </HeaderElement>
        <HeaderControls>
		  <div style={{display: 'none'}} onClick={() => setCopied('https://swap.acptsc.com/#/swap?address=' + account)}>
			<img style={{ paddingBottom: "0.25rem", maxHeight: '2rem', marginLeft: '0.rem', marginTop: '0.5rem' }} src={ImgShare} alt="logo" />
			{isCopied ? (
			  <TransactionStatusText>
			    <TransactionStatusText>{t('zylInvite')}</TransactionStatusText>
			  </TransactionStatusText>
			) : (
			  <TransactionStatusText>
			  </TransactionStatusText>
			)}
			{isCopied ? '' : ''}
		  </div>
          <HeaderElement style={{display: isCopied?'none':''}}>
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} BNB
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            <VersionSwitch />
            <Settings />
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
	  <RowBetween style={{ alignItems: 'flex-start' }} padding="0rem 1rem 0 1rem">
	    <HeaderElement>
	    </HeaderElement>
	    <HeaderControls>
	      <HeaderElement>
			<img style={{ paddingBottom: "0.25rem", maxHeight: '1.5rem', marginLeft: '0.5rem' }} src={ImgAngel} alt="logo" />
			<span style={{color:"#888689", fontWeight:"bold", marginLeft:"0.1rem"}}>{lastPrice?parseFloat(lastPrice).toFixed(12):0.0027}</span>
			<span style={{color:"#888689", marginLeft: '0.1rem' }}>$</span>
			<img style={{ paddingBottom: "0.25rem", marginTop: '0.25rem' , maxHeight: '1.25rem', marginLeft: '1rem' }} src={lastUpDown&&parseFloat(lastUpDown)<=0?ImgDown:ImgUp} alt="logo" />
			<span style={lastUpDown&&parseFloat(lastUpDown)<=0?{color:"#ff646d", fontWeight:"bold", marginLeft:"0.1rem"}:{color:"#22d49f", fontWeight:"bold", marginLeft:"0.1rem"}}>{lastUpDown?(parseFloat(lastUpDown) * 100).toFixed(2):137.86}</span>
			<span style={lastUpDown&&parseFloat(lastUpDown)<=0?{color:"#ff646d", fontWeight:"bold"}:{color:"#22d49f", fontWeight:"bold"}}>%</span>
	      </HeaderElement>
	      <HeaderElementWrap>
			<ExternalLink href="https://dex.acptsc.com/token/0x781e25acc507d1c987d3c34885abe6e29e86b8a6-bsc">
				<img style={{ paddingBottom: "0.25rem", maxHeight: '2rem', marginLeft: '0.75rem' }} src={DexSvg} alt="logo" />
			</ExternalLink>
	      </HeaderElementWrap>
	    </HeaderControls>
	  </RowBetween>
    </HeaderFrame>
  )
}
