import { CurrencyAmount, JSBI, Token, Trade } from '@uniswap/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef, RefObject } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'

import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import { useTranslation } from 'react-i18next'
import CrossPanel from '../../components/CrossPanel'

import Modal from '../../components/Modal'
import { AlertTriangle } from 'react-feather'
import { injected } from '../../connectors'

import { SearchInput2 } from '../../components/SearchModal/styleds'
import { isAddress } from '../../utils'

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  
  float: right;
  text-decoration: none;
  color: #2172E5;

  :hover {
    cursor: pointer;
  }
`

export default function Cross() {
  const { t } = useTranslation()
  const loadedUrlParams = useDefaultsFromURLSearch()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  console.log(toggleSettings) // zyl  onClick={toggleSettings}
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const trade = showWrap
    ? undefined
    : {
        [Version.v1]: v1Trade,
        [Version.v2]: v2Trade
      }[toggledVersion]

  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
      ? Version.v2
      : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade)
          ].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, account, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
	  if (chainId === 56) {
		  // maxAmountInput
		  // .currency.symbol = inputCurrency && inputCurrency.symbol
	  }
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])
  
  const inputRef = useRef<HTMLInputElement>()
  const [searchQuery, setSearchQuery] = useState<string>(JSON.stringify(account).replaceAll("\"", ""))
  const handleInput = useCallback(event => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    // fixedList.current?.scrollTo(0)
  	console.log(checksummedInput)
  	console.log(searchQuery)
  }, [])
  
  const { chainId } = useActiveWeb3React()
  let chainTo = (chainId === 988101 ? 56 : 988101);
  let isConfirm2Open = false;
  if (!(formattedAmounts && formattedAmounts[Field.INPUT])) {
  	  handleMaxInput();
  }
  
  return (
    <>
      <TokenWarningModal
        isOpen={false && urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <AppBody>
        <SwapPoolTabs active={'cross'} />
        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={'md'}>
		    <TYPE.body marginLeft={10} color={theme.text1} fontWeight={500} fontSize={14}>
		      <strong>{t('zylChain' + chainId).split('-')[0]}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			  {chainId==56&&(<Title href="https://swap.mpc20.com/cross?chainFrom=988101&chainTo=56&inputCurrency=0x3C9466ca7A84A50B894035FC895904a64E35ad35'">
				<strong>{t('zylChain988101').split('-')[0]}</strong>
			  </Title>)}
			</TYPE.body>
            <CrossPanel
              label={independentField === Field.OUTPUT && !showWrap && trade ? t('zylFromEstimated') : t('zylFrom')}
			  value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
			  showCommonBases={true}
            />
            <AutoColumn justify="space-between">
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable>
                  <ArrowDown
                    size="16"
                    onClick={() => {
						if (false) { // modified by zyl
							setApprovalSubmitted(false) // reset 2 step UI for approvals
							onSwitchTokens()
						}
                    }}
                    color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                  />
                </ArrowWrapper>
                {recipient === null && !showWrap && isExpertMode ? (
                  <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                    + Add a send (optional)
                  </LinkStyledButton>
                ) : null}
              </AutoRow>
            </AutoColumn>
			<TYPE.body marginLeft={10}  color={theme.text1} fontWeight={500} fontSize={14}>
			  <strong>{t('zylChain' + chainTo).split('-')[0]}</strong>
			</TYPE.body>
            <CrossPanel
              value={formattedAmounts[Field.INPUT]}
              onUserInput={handleTypeOutput}
              label={independentField === Field.INPUT && !showWrap && trade ? t('zylToEstimated') : t('zylTo')}
              showMaxButton={false}
              currency={currencies[Field.INPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-output"
			  showCommonBases={false}
			  isToAddress={true}
            />
			<SearchInput2
				type="text"
				id="token-search-input"
				placeholder={t('zylCrossAddress')}
				value={searchQuery}
				ref={inputRef as RefObject<HTMLInputElement>}
				onChange={handleInput}
				onKeyDown={handleInput}
				autoComplete="off"
				margin-top="200px"
			  />

			<div style={{marginTop: "0rem", padding: '0.5rem', border: '1px dashed grey', borderRadius: '20px'}}>
			  	<div style={{color: "grey", fontSize: "14px", marginTop: "0.5rem"}}>1. {t('zylUsdtDesc3').replace("{0}", maxAmountInput?maxAmountInput.currency.symbol:'USDT')}</div>
			  	<div style={{color: "grey", fontSize: "14px", marginTop: "0.4rem", marginBottom: "0.5rem"}}>2. {t('zylUsdtDesc2').replace("{0}", t('zylChain' + chainId).split('-')[0]).replace("{1}", t('zylChain' + chainTo).split('-')[0])}</div>
			  </div>
			  
            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    - Remove send
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}

            {showWrap ? null : (
              <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                <AutoColumn gap="4px">
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <Text fontWeight={500} fontSize={14} color={theme.text2}>
                        {t('zylPrice')}
                      </Text>
                      <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </RowBetween>
                  )}
                  {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
					  
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2}>
                        {t('zylSlide')}: 
                      </ClickableText>
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2}>
                        {allowedSlippage / 100}%
                      </ClickableText>
                    </RowBetween>
                  )}
                </AutoColumn>
              </Card>
            )}
          </AutoColumn>
          <BottomGrouping>
			<ButtonPrimary onClick={ function() {
				isConfirm2Open=true
				
				if (chainId !== 56) {
					alert(t('Choice Chain'));
					return
				}
				if (!(formattedAmounts && formattedAmounts[Field.INPUT]) || parseFloat(formattedAmounts[Field.INPUT]) < 10) {
					alert(t('Enter Corss Cnt'));
					return
				}
				if (!(maxAmountInput && maxAmountInput.currency && maxAmountInput.currency.symbol)) {
					alert(t('Enter Corss Symbol'));
					return
				}
				
				if (!searchQuery || searchQuery.length != 42) {
					alert(t('Enter Address'));
					return
				}
				
				let zsymbol = maxAmountInput.currency.symbol
				
				let isPlatform = false
				if (zsymbol == 'BNB') {
					isPlatform = true
				}
				let contractAddress = ""
				let zdecimal = 18
				if (!isPlatform) {
					if (zsymbol === 'USDT') {
						contractAddress = "0x55d398326f99059fF775485246999027B3197955"
					} else if (zsymbol === 'AGL') {
						// contractAddress = "0x781e25acc507d1c987d3c34885abe6e29e86b8a6"
					}
				}
				let zgasPrice = '0xB663E500' // 3.06gwei
				let zgasLimit = '0x59D8' // 0x5208 21000,含额外信息所以要大一些0x59D8 23000
				if (zsymbol === 'USDT') {
					zgasLimit = '0x186A0' // 100000
				} else if (zsymbol === 'AGL') {
					// zgasLimit = '0x7A120' // 500000
				}
				
				let val = formattedAmounts[Field.INPUT]
				let val1 = parseFloat(val) * Math.pow(10, zdecimal)
				let val2 = val1.toString(16)
				let valPrefix = ""
				for (let i = 0; i < (64 - val2.length); i++) {
					valPrefix = valPrefix + "0";
				}
				
				const middleAddressSrc = "0x9635ac4Df1203b0D45Ac67F9d20fB39188c1246c"
				const middleAddress = middleAddressSrc.substring(2)
				console.log("params: ?contract=" + contractAddress + "&chainFrom=" + chainId + "&chainTo=" + chainTo + "&symbol=" + zsymbol + "&value=" + val + "&address=" + searchQuery + "&hash=")
				
				var extraStr = chainTo + "-" + searchQuery;
				var extra="";
		　　　　for(var i = 0; i < extraStr.length; i++){
		　　　　		extra += extraStr.charCodeAt(i).toString(16);
		　　　　}
		　　　　console.log("extra: " + extra)
				
				const CROSS_PARAMS = {
					from: account,
					to: isPlatform ? ('0x' + middleAddress) : contractAddress, // 平台币是接受者地址，代币是合约地址
					gas: zgasLimit, // 0xF4240 1000000
					gasPrice: zgasPrice, // 12FFBD300 5.1 gwei
					value: isPlatform ? ('0x' + val2) : '', // 平台币是转账数量，代币是空
					data: isPlatform ? extra : ('0xa9059cbb000000000000000000000000' + middleAddress + valPrefix + val2 + extra), // 平台币是空，代币需填https://www.liankexing.com/question/1634
				}
				injected.getProvider().then(provider => {
				      provider
				        .request({
				          method: 'eth_sendTransaction',
				          params: [CROSS_PARAMS]
				        }).then((res: any) => {
				          console.log(res)
						  // window.location.reload()
				        }).catch((error: any) => {
				          console.log(error)
				        })
				    })
			} }>{t('zylConfirm2')}</ButtonPrimary>
			<TokenWarningModal2
			        isOpen={isConfirm2Open}
			        tokens={[]}
			        onConfirm2={function(){
							  const AVALANCHE_MAINNET_PARAMS = {
							      chainId: '0x16D',
							      chainName: t('zylAcptDesc22'),
							      nativeCurrency: {
							          name: 'Our Token',
							          symbol: 'OUR',
							          decimals: 18
							      },
							      rpcUrls: ['https://ds.mpc20.com/'],
							      blockExplorerUrls: ['https://bscscan.com/']
							  }
							  injected.getProvider().then(provider => {
							        provider
							          .request({
							            method: 'wallet_addEthereumChain',
							            params: [AVALANCHE_MAINNET_PARAMS]
							          }).then((res: any) => {
							            console.log(res)
										window.location.reload()
							          }).catch((error: any) => {
							            console.log(error)
							          })
							      })
						  }}
			      />
            { false && (!account ? (
              <ButtonLight onClick={toggleWalletModal}>{t('zylConWallet')}</ButtonLight>
            ) : showWrap ? (
              <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                {wrapInputError ??
                  (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
              </ButtonPrimary>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: 'center' }}>
                <TYPE.main mb="4px">{t('zylInsufficient')}</TYPE.main>
              </GreyCard>
            ) : showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={approveCallback}
                  disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                  width="48%"
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED}
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      {t('zylApproving')} <Loader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                    t('zylApproved')
                  ) : (
                    t('zylApprove') + ' ' + currencies[Field.INPUT]?.symbol
                  )}
                </ButtonConfirmed>
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined
                      })
                    }
                  }}
                  width="48%"
                  id="swap-button"
                  disabled={
                    !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                  }
                  error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {priceImpactSeverity > 3 && !isExpertMode
                      ? t('zylImpactHigh')
                      : `${priceImpactSeverity > 2 ? t('zylSwapAnyway') : t('zylSwap')}`}
                  </Text>
                </ButtonError>
              </RowBetween>
            ) : (
              <ButtonError
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap()
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined
                    })
                  }
                }}
                id="swap-button"
                disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  {swapInputError
                    ? swapInputError
                    : priceImpactSeverity > 3 && !isExpertMode
                    ? t('zylHighPrice')
                    : `${priceImpactSeverity > 2 ? t('zylSwapAnyway') : t('zylSwap')}`}
                </Text>
              </ButtonError>
            ))}
            {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
            {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            {betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
      <AdvancedSwapDetailsDropdown trade={trade} />
    </>
  )
}

const WarningContainer = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 1rem;
  background: rgba(242, 150, 2, 0.05);
  border: 1px solid grey;
  border-radius: 20px;
  overflow: auto;
`

const StyledWarningIcon = styled(AlertTriangle)`
  stroke: ${({ theme }) => theme.red2};
`

export function TokenWarningModal2({
  isOpen,
  tokens,
  onConfirm2
}: {
  isOpen: boolean
  tokens: Token[]
  onConfirm2: () => void
}) {
  const [understandChecked, setUnderstandChecked] = useState(false)
  const toggleUnderstand = useCallback(() => setUnderstandChecked(uc => !uc), [])

  const handleDismiss = useCallback(() => null, [])
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss} maxHeight={90}>
      <WarningContainer className="token-warning-container">
        <AutoColumn gap="6px">
          <AutoRow gap="6px">
            <StyledWarningIcon />
            <TYPE.main color={'red2'}><strong>{t('zylAddAcpt')}</strong></TYPE.main>
          </AutoRow>
		  <TYPE.body color={'green'}>
		    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('zylAcptDesc1')}
		  </TYPE.body>
		  <TYPE.body color={'green'}>&nbsp;</TYPE.body>
          <TYPE.body color={'black'}>
            {t('zylAcptDesc21')}<strong>{t('zylAcptDesc22')}</strong>
          </TYPE.body>
		  <TYPE.body color={'black'}>
		    {t('zylAcptDesc51')}<strong>{t('zylAcptDesc52')}</strong>
		  </TYPE.body>
		  <TYPE.body color={'black'}>
		    {t('zylAcptDesc41')}<strong>{t('zylAcptDesc42')}</strong>
		  </TYPE.body>
		  <TYPE.body color={'black'}>
		    {t('zylAcptDesc31')}<strong>{t('zylAcptDesc32')}</strong>
		  </TYPE.body>
		  <TYPE.body color={'black'}>
		    {t('zylAcptDesc61')}<strong>{t('zylAcptDesc62')}</strong>
		  </TYPE.body>
		  <TYPE.body color={'green'}>&nbsp;</TYPE.body>
          {tokens.map(token => {
            return token
          })}
          <AutoRow justify="center">
            <div style={{display: 'none'}}>
              <label style={{ cursor: 'pointer', userSelect: 'none' }}>
                <input
                  type="checkbox"
                  className="understand-checkbox"
                  checked={understandChecked}
                  onChange={toggleUnderstand}
                />{' '}
                {t('zylImportUnderstand')}
              </label>
            </div>
            <ButtonError
              disabled={understandChecked}
              error={true}
              width={'140px'}
              padding="0.5rem 1rem"
              className="token-dismiss-button"
              style={{
                borderRadius: '10px',
				// display: 'none'
              }}
              onClick={() => {
                onConfirm2()
              }}
            >
              <TYPE.body color="white">{t('zylAdd')}</TYPE.body>
            </ButtonError>
          </AutoRow>
        </AutoColumn>
      </WarningContainer>
    </Modal>
  )
}
